<template>
  <div class="s-orders-page">
    <div class="s-orders-nav">
      <span @click="backToIndex" class="s-flex-basis-25 pl-3">
        <BIconChevronLeft font-scale="1.2" />
      </span>
      <div class="text-center s-flex-basis-50">訂單明細</div>
    </div>

    <template v-if="fetching"><Loading /></template>
    <template v-else> </template>
    <div v-if="order" class="p-2 d-flex justify-content-around s-order-detail">
      <div class="mt-2">
        <p class="s-text-gray-darker s-whitespace-nowrap">
          訂購日 : <span>{{ createdDateTime }}</span>
        </p>
        <p class="s-text-gray-darker s-whitespace-nowrap">
          訂單號 : <span>{{ order.outer_order_number }}</span>
        </p>
        <p class="s-whitespace-nowrap s-flex-basis-50 s-text-gray-darker">
          {{ deliveryText }}
        </p>
        <p class="s-font-bold s-orders-flex-line s-flex-basis-50 text-right">
          <span class="s-text-gray-darker s-mr-2">共 {{ quantity }} 件</span>
          <span class="s-text-secondary">{{ $currency(order.total) }}</span>
        </p>
      </div>

      <div class="d-flex flex-column justify-content-center s-space-y-4">
        <button
          class="s-orders-btn s-orders-btn--secondary"
          @click.prevent="$refs.confirm.open()"
          v-if="status === 'processing' && order.source_type === 3"
        >
          取消訂單
        </button>

        <button
          class="s-orders-btn s-orders-btn--secondary"
          @click="redirectToPoyaReturnUrl()"
          v-if="status === 'processing' &&
            (order.source_type === 1 || order.source_type === 2) &&
            order.status === 'WaitingToShipping'
            "
        >
          取消訂單
        </button>

        <button
          class="s-orders-btn"
          @click="route.push({ name: 'LiffOrderEdit' })"
          v-if="status === 'processing' && order.source_type === 3"
        >
          修改數量
        </button>
      </div>
    </div>

    <template v-if="order.items.length">
      <ul class="s-mb-6">
        <li v-for="(item, i) in order.items" :key="i">
          <div class="s-order-item align-items-center">
            <div class="s-order-item-image">
              <div class="s-order-item-image-wrapper">
                <img :src="image(item)" />
              </div>
            </div>
            <div
              class="s-order-item-info s-space-y-2 s-text-sm s-text-gray-darker"
            >
              <p class="">{{ item.title }}</p>
              <p class="s-whitespace-nowrap">
                {{ item.outer_order_item_number }}
              </p>
              <p>{{ item.quantity }} 件 {{ $currency(item.total_price) }}</p>
            </div>
          </div>
        </li>
      </ul>
    </template>

    <Modal ref="confirm" title="您是否要取消訂單？">
      您是否要取消訂單？
      <template #actions="{ close }">
        <div class="s-flex s-space-x-3">
          <button
            class="s-btn s-btn-secondary"
            type="button"
            @click="cancelOrder"
          >
            取消訂單
          </button>
          <button class="s-btn s-btn-primary" type="button" @click="close">
            保留訂單
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import { BIconChevronLeft } from "bootstrap-vue";
import Modal from "@/components/Page/Liff/Shared/Modal";
import Loading from "@/components/Page/Liff/Shared/Loading";
import withFetcher from "@/mixins/liff/withFetcher";

export default {
  mixins: [withFetcher],
  components: { Loading, Modal, BIconChevronLeft },
  inject: ["$currency", "$alert"],
  data: () => ({
    order: {
      items: [],
    },
    status: null,
  }),
  computed: {
    quantity() {
      if (this.order?.items.length === 0) {
        return 0;
      }
      return this.order?.items.map((el) => el.quantity).reduce((a, b) => a + b);
    },
    deliveryText() {
      const delType = {
        Home: "宅配取貨",
        LocationPickup: "門市自取 : ",
      };
      let delTypeText = delType[this.order.delivery_type];
      if (!this.order.delivery_type) {
        delTypeText = '取貨方式 : '
      }
      return delTypeText + (this.order.branch_name ?? this.order.outer_branch_name ?? '');
    },
    createdDateTime() {
      return moment(this.order?.created_at).format("YYYY/MM/DD");
    },
  },
  methods: {
    ...mapActions({
      show: "liffOrder/showOrder",
      cancel: "liffOrder/cancelOrder",
    }),
    async fetch() {
      const data = await this.show({ id: this.$route.params.id });
      this.order = data;
      this.status = this.$route.params.status
    },
    backToIndex() {
      this.$router.push({
        name: "LiffOrdersStatus",
        params: { status: this.status },
      });
    },
    image(item) {
      const images = item?.product?.images || [{}];
      return images[0].pic_url;
    },
    async cancelOrder() {
      try {
        await this.cancel({ id: this.$route.params.id });
      } catch ({ message }) {
        return this.$alert(message);
      }

      this.$router.push({
        name: "LiffGroupBuyingOrdersCanceled",
        params: { id: this.groupBuying.id },
      });
    },
    redirectToPoyaReturnUrl () {
      const url = this.order.is_original_org ? 'https://www.poyabuy.com.tw/V2/TradesOrder/TradesOrderList?shopId=40916' : 'https://www.poyahomebuy.com.tw/V2/TradesOrder/TradesOrderList?shopId=40917'
      window.location.href = url;
    }
  },
};
</script>

<style lang="scss" scoped>
.s-orders-page {
  margin-top: -1.5rem;
}

.s-orders-nav {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
  padding: 0.75rem 0;
  border-bottom: solid 1px var(--s-gray-lighter);
}

.s-orders-flex-line {
  display: flex;
}

.s-order-detail {
  border-bottom: solid 1px var(--s-gray-lighter);
}

.s-order-item {
  padding: 0.75rem 0;
  border-bottom: solid 1px var(--s-gray-lighter);
  display: flex;
  min-height: 7rem;
}

.s-order-item-image {
  flex-shrink: 0;
  margin-right: 0.5rem;
  border-radius: 0.375rem;
  font-size: 0;
}

.s-order-item-image-wrapper {
  width: 5rem;
  height: 5rem;
  margin: 0.25rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
  background-color: var(--s-white);
}

.s-order-item-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.s-order-item-info {
  margin-right: 0.5rem;
}

.s-order-item-tag {
  flex-shrink: 0;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 5.5rem;
}

.s-tag {
  text-align: center;
}

.s-flex-basis-25 {
  flex-basis: 25%;
}

.s-flex-basis-50 {
  flex-basis: 50%;
}

.s-flex-basis-75 {
  flex-basis: 75%;
}

.s-orders-btn {
  color: var(--s-gray);
  border: 1px solid var(--s-gray);
  text-decoration: none;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.5;
  padding: 2px 6px;

  &--secondary {
    color: var(--s-secondary);
    border: 1px solid var(--s-secondary);
  }
}
</style>
